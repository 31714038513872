import { uniq } from "lodash-es"
import { mapProductsAlgolia, ProductAlgolia } from "~/utils/mapProducts"
import { checkEnvSite } from "~/server/utils/getAlgoliaConfig"
import { SearchResponse } from "@nuxtjs/algolia"
import { buildExcludeFilter } from "~/utils/algolia"

export default async () => {
  const { orders, fetchOrders } = useCLCustomer()

  const initBuyAgain = fetchOrders

  const { search } = useAlgoliaSearch(`TOP_PRODUCTS${checkEnvSite}_name-asc`) // pass your index name as param

  const skuCodes = computed(() =>
    uniq(
      orders.value
        ?.flatMap((order) => order.line_items)
        .filter((lineItem) => lineItem!.item_type === "skus")
        .map((lineItem) => lineItem!.sku_code!) ?? []
    )
  )

  const getBuyAgainProducts = (skuCodes: string[]) =>
    search({
      query: "",
      requestOptions: {
        filters: skuCodes
          .map((skuCode) => `productCode:${skuCode}`)
          .join(" OR "),
        hitsPerPage: 100,
        clickAnalytics: true,
        ...buildExcludeFilter({ excludeOutOfMarket: true })
      }
    })

  const { data: buyAgainProducts } = await useAsyncData(
    "buyAgainProducts",
    () =>
      skuCodes.value.length
        ? getBuyAgainProducts(skuCodes.value as string[])
        : new Promise<SearchResponse<unknown>>({
            hits: []
          }),
    {
      immediate: false,
      default: () => [],
      deep: true,
      watch: [skuCodes],
      transform: (searchResponse) => {
        return searchResponse?.hits?.length
          ? mapProductsAlgolia(searchResponse.hits)
          : []
      }
    }
  )

  return {
    initBuyAgain,
    buyAgainProducts
  }
}
