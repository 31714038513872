import { storeToRefs } from "pinia"
import { useUserStore } from "~/stores/user"

export default () => {
  const userStore = useUserStore()
  const { userId } = storeToRefs(userStore)
  const isLogged = useIsLogged()
  const { $cl } = useNuxtApp()

  const getCustomerOrders = async () => {
    if (!isLogged.value) {
      return null
    }

    const response = await $cl.customers.orders(userId.value, {
      include: ["line_items", "line_items.item"],
      filters: {
        status_not_in: "draft,pending"
      },
      sort: { placed_at: "desc" }
    })

    return response
  }

  // const { data: customer, execute: fetchCustomer } = useLazyAsyncData(
  //   "customer",
  //   getCustomer,
  //   {
  //     immediate: false
  //   }
  // )
  const { data: orders, execute: fetchOrders } = useLazyAsyncData(
    "customer-orders",
    getCustomerOrders,
    {
      immediate: false
    }
  )

  return {
    // customer,
    orders,
    // fetchCustomer,
    fetchOrders
  }
}
